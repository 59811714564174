import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '~/components/layout'
import SEO from '~/components/seo'
import Input from '~/components/input'
import Textarea from '~/components/textarea'
import Button from '~/components/button'

const apiUrl = process.env.GATSBY_STRAPI_URL || 'http://localhost:1337'

const ContactPage = ({ data: { allStrapiContact } }) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)

  const resetFileInput = e => {
    e.preventDefault()
    document.querySelector('input[type="file"]').value = ''
    setFile(null)
  }

  const contactData = allStrapiContact.nodes[0]
  const seo = {
    title: 'Contactez-nous',
    metaDescription:
      'Contactez nos différentes salles de ventes pour toute question.',
  }

  const tenMo = 10500000

  const sendMail = e => {
    e.preventDefault()
    const name = e.target.name.value
    const email = e.target.email.value
    const phone = e.target.phone.value
    const message = e.target.message.value
    const file = document.querySelector('input[type="file"]').files[0]

    const formData = new FormData()
    formData.append('picture', file)
    formData.append('name', JSON.stringify(name))
    formData.append('email', JSON.stringify(email))
    formData.append('phone', JSON.stringify(phone))
    formData.append('message', JSON.stringify(message))

    fetch(`${apiUrl}/contact`, {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (res.status == 200) {
          setError(null)
          e.target.name.value = ''
          e.target.email.value = ''
          e.target.phone.value = ''
          e.target.message.value = ''
          document.querySelector('input[type="file"]').value = ''
          alert('Votre message a bien été envoyé !')
        }
      })
      .catch(err => {
        console.log(err.message)
        setError('Erreur serveur')
      })
  }

  return (
    <Layout>
      <SEO seo={seo} />
      <div
        className="bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${contactData.form.background.url})` }}
      >
        <div className="grid grid-cols-12 container">
          <form
            className="col-span-full tablet:col-start-2 tablet:col-end-12 bg-gray-100 px-3 mb-10 push-content"
            onSubmit={e => sendMail(e)}
          >
            <div className="text-center mt-2 text-lg">
              {contactData.form.title}
            </div>
            <Input placeholder="Votre nom" name="name" isRequired />
            <Input placeholder="Votre e-mail" name="email" isRequired />
            <Input
              placeholder="Votre numéro de téléphone"
              name="phone"
              isRequired
            />
            <Textarea placeholder="Votre message" name="message" isRequired />
            <div className="mt-4 mb-6">
              <label className="block mb-1">
                Partager une photo (optionnel - 10 Mo max).
              </label>
              <input
                accept="image/*"
                type="file"
                name="picture"
                onChange={e => setFile(e.target.files[0])}
              />
              {file && file.size > tenMo && (
                <div className="text-red mt-1">
                  Le fichier dépasse 10 Mo.{' '}
                  <a
                    href="#"
                    className="text-red underline"
                    onClick={e => {
                      resetFileInput(e)
                    }}
                  >
                    Retirer mon image.
                  </a>
                </div>
              )}
            </div>
            <div className="mb-4">
              Aucune donnée personnelle n’est conservée par notre site via ce
              formulaire.
            </div>
            <Button
              isDisabled={file && file.size > tenMo}
              label="Envoyer"
              type="submit"
            />
            {error !== null ? (
              <div className="text-red text-center mb-2">{error}</div>
            ) : null}
          </form>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="grid grid-cols-12 container py-5">
          <div className="col-start-1 col-end-13">
            <div className="font-bold p-2 w-fit px-2 text-xl">
              {contactData.block.title}
            </div>
            <ReactMarkdown className="px-2 pt-4 pb-8 border-t">
              {contactData.block.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contactQuery {
    allStrapiContact {
      nodes {
        form {
          background {
            url
          }
          title
        }
        block {
          content
          title
        }
      }
    }
  }
`

export default ContactPage
